<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Events.com Integration for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Connect this event with an events.com event for automatic registration sync.
        </p>

        <v-alert v-if="$route.query.error" type="error">
          <p v-if="$route.query.error == 'EVENTSCOM_NO_ORG'">We cannot determine which organization to sync. Only the owners of an organization are able to setup the events.com sync.</p>
          <p v-else>Oops, an unexpected error occurred while connecting with Events.com. </p>
          <p>Please reach out to us and provide as much information as possible to allow us to help troubleshoot this issue.</p>
        </v-alert>
        <v-alert v-if="isConnectedWithEventsComEvent" type="success">
          <p>This event is connected with an events.com event. Registration data is synced <strong>every 15 minutes</strong> automatically.</p>
          <p><v-btn @click="syncNow">Sync Now</v-btn></p>
        </v-alert>
        <v-alert v-if="event.sync" type="success">
          <p>Last sync was at {{ event.sync.timestamp | localDate('ddd L LTS Z') }}: <strong>{{ event.sync.msg }}</strong></p>
        </v-alert>

        <div v-if="!isConnectedWithEventsComEvent">
          <v-alert type="info">
            <p>This event is not yet connected with an events.com event. Please select an event from your events.com organization to connect with.</p>
          </v-alert>
          <v-radio-group v-model="selectedProductId" column label="Events.com event">
            <v-radio v-for="(item, idx) in eventsComEvents" :key="idx" :value="item.productId">
              <template v-slot:label>
                <span>
                <strong>{{ item.productName }}:</strong>
                {{ item.type }} (<a :href="item.eventUrl" target="_blank">View on events.com</a>)
                </span>
              </template>
            </v-radio>
            </v-radio-group>
          <v-btn :disabled="!selectedProductId" @click="connect">Connect</v-btn>
        </div>
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "IntegrationsEventsCom",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      provider: null,
      selectedProductId: null,
      siteData: siteData,
      eventsComEvents: [],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      if (!this.isConnectedWithEventsComEvent) {
        this.eventsComEvents = (await eventManagerService.integrationsEventsComEvents(id)).data;
        console.log('this.eventsComEvents', this.eventsComEvents);
      }
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async connect() {
      const response = (await eventManagerService.integrationsEventsComConnect(this.event.id, this.selectedProductId)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully connected with selected event.');
    },
    async syncNow() {
      const response = (await eventManagerService.integrationsEventsComSync(this.event.id)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully synced registration data.');
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Integrations', exact: true, to: { name: 'eventmanagerIntegrations', params: {id: this.event.id}} },
        { text: `Events.com Integration`, disabled: true },
      ];
    },
    isConnectedWithEventsComEvent() {
      return this.event && this.event.meta && this.event.meta.some(x => x.key == 'eventscom.p_id');
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

